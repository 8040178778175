const I18nextChainedBackend = require('i18next-chained-backend/dist/cjs/i18nextChainedBackend')
const I18NextHttpBackend = require('i18next-http-backend/cjs')
const axios = require('axios')

const loadResources = async (locale) => {
    let loc = locale !== 'default' ? locale : 'en-DXB'
    const isBrowser = typeof window !== 'undefined'

    let baseUrl = 'https://joinswapp.com'
    if (isBrowser) {
        const urlObject = new URL(window.location.href)
        const hostName = urlObject.origin
        baseUrl = window.location.hostname === 'localhost' ? 'https://dev.joinswapp.com' : hostName
    } else {
        baseUrl = process.env.I18N_BASE_URL
    }

    return await axios
        .get(`${baseUrl}/configuration/products/DAILY/translations/locales/${loc.replace('_', '-').toUpperCase()}`, {
            timeout: 60000,
        })
        .then(({ data }) => {
            return data.reduce((acc, curr) => {
                acc[curr.id] = curr.text
                return acc
            }, {})
        })
        .catch((error) => {
            const serverMsg = error.message || ''
            throw new Error(
                `[loadResources] Something went wrong during download translation file. Error message: ${serverMsg}}, locale: ${locale}`,
            )
        })
}

module.exports = {
    debug: false,
    i18n: {
        // Default is required https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
        locales: ['default', 'en-US', 'en-DXB', 'ar-DXB', 'en-AUH', 'ar-AUH'],

        defaultLocale: 'default',
        localeDetection: false,
        returnEmptyString: false,
    },
    returnNull: false,
    // Namespace is required
    ns: ['common'],
    serializeConfig: false,
    use: [I18nextChainedBackend],
    backend: {
        backends: [I18NextHttpBackend],
        allowMultiLoading: true,
        backendOptions: [
            {
                loadPath: '{{lng}}|{{ns}}',
                reloadInterval: 600000,
                request: (options, url, payload, callback) => {
                    try {
                        const lng = url.split('|')[0]

                        loadResources(lng).then((response) => {
                            callback(null, {
                                data: response,
                                status: 200,
                            })
                        })
                    } catch (error) {
                        const serverMsg = error.message || 'Something went wrong during download translation file'
                        console.log(
                            JSON.stringify({
                                label: 'next-i18next.config',
                                level: 'error',
                                message: `Error message: ${serverMsg}}, locale: ${url}`,
                                timestamp: `${new Date()}`,
                            }),
                        )
                        callback(error, null)
                    }
                },
            },
        ],
    },
}
